export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export type OptionsFlags<T> = {
  [P in keyof T]?: boolean;
};

export type DeepRequired<T> = {
  [K in keyof T]-?: T[K] extends object ? Required<DeepRequired<T[K]>> : Required<T[K]>;
};

export type Nullable<T> = NonNullable<T> | null;

export type PartialToNullable<T> = {
  [K in keyof T]-?: undefined extends T[K] ? Nullable<T[K]> : T[K];
};

export type Nil = null | undefined;

export type NonEmptyArray<T> = [T, ...T[]];

// Solving a known TS issue with Array lengths. See: https://stackoverflow.com/a/56006703
export function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  return arr?.length > 0;
}

export type * from './snakeCaseKeys';
export type * from './deepDotNotationKeys';
