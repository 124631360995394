import { useVimOsMigrationAPI } from '@getvim-core-apps/vim-os-migration-api';
import { isNil } from 'lodash-es';
import React, { FC, useCallback } from 'react';
import { DetailsCard, TextTransformEnum } from '@getvim/atomic-ui';
import { formatDateString } from '@getvim/react-app-infra';
import { GetMemberResult } from '../../../types';
import { EligibilityCardItem } from '../../molecules';
import { ClickAction, DetailsCardType, Feature } from '../../../utils/analytics';
import './index.less';

export interface MemberDetailsListProps {
  eligibility: GetMemberResult;
}

const formatCoPayStr = (copay?: number, frequency?: string) =>
  !isNil(copay) ? `$${copay}${!isNil(frequency) ? ` / ${frequency}` : ''}` : 'Not available';

export const MemberDetailsList: FC<MemberDetailsListProps> = ({ eligibility }) => {
  const {
    memberName,
    dateOfBirth,
    memberId,
    groupNumber,
    plan,
    coverage,
    primaryProvider,
    referral,
    coPay,
  } = eligibility;

  const {
    platformActions: { analytics },
  } = useVimOsMigrationAPI();

  const handleToggle = useCallback(
    (expanded: boolean) => {
      analytics.track('vim_chart_item_click', {
        feature: Feature.Eligibility,
        action: expanded ? ClickAction.Open : ClickAction.Close,
        detailsCardType: DetailsCardType.MemberDetails,
        app_source_displayed: eligibility?.dataSource,
      });
    },
    [eligibility?.dataSource, analytics],
  );

  let referralValue;
  const pcpCopay = coPay?.pcp?.inNetwork;
  const pcpCopayFrequency = coPay?.pcp?.frequency;
  const specialistCopay = coPay?.specialist?.inNetwork;
  const specialistCopayFrequency = coPay?.specialist?.frequency;

  if (!isNil(referral)) {
    referralValue = referral ? 'Required' : 'Not required';
  }

  const renderTopCard = () => {
    return (
      <>
        <div className="row-member-details-list">
          <EligibilityCardItem
            id="name"
            label="Name"
            value={memberName?.toLowerCase()}
            valueTextTransform={TextTransformEnum.capitalize}
          />
          <EligibilityCardItem
            id="dob"
            label={'Date of birth'}
            value={dateOfBirth ? formatDateString(dateOfBirth) : ''}
          />
        </div>
        <div className="row-member-details-list">
          {memberId && <EligibilityCardItem id="memberID" label="Member ID" value={memberId} />}
          {groupNumber && (
            <EligibilityCardItem id="groupNumber" label={'Group number'} value={groupNumber} />
          )}
        </div>
      </>
    );
  };
  return (
    <DetailsCard
      title={'Member & eligibility details'}
      iconClass="icon-clipboard"
      onToggle={handleToggle}
      smallContentPadding
    >
      {renderTopCard()}
      {plan && (
        <EligibilityCardItem
          label="Plan"
          value={plan}
          valueTextTransform={TextTransformEnum.upperCase}
        />
      )}
      {coverage && <EligibilityCardItem label="Coverage" value={coverage} />}
      {primaryProvider && (
        <EligibilityCardItem
          label={'Primary care provider'}
          value={primaryProvider.toLowerCase()}
          valueTextTransform={TextTransformEnum.capitalize}
        />
      )}
      {referralValue && <EligibilityCardItem label="Referral" value={referralValue} />}

      <>
        {isNil(pcpCopay) && isNil(specialistCopay) ? (
          <></>
        ) : (
          <div className="copay-row">
            <EligibilityCardItem
              label="Primary care co-pay"
              value={formatCoPayStr(pcpCopay, pcpCopayFrequency)}
            />
            <EligibilityCardItem
              label="Specialist co-pay"
              value={formatCoPayStr(specialistCopay, specialistCopayFrequency)}
            />
          </div>
        )}
      </>
    </DetailsCard>
  );
};
