import { gql } from '@apollo/client';

export const GET_ELIGIBILITY_QUERY = gql`
  query getEligibility($input: GetEligibilityInput!) {
    getEligibility(input: $input) {
      eligibilityPlan {
        insuranceDetails {
          groupCode
          plan
          effectiveStartDate
          effectiveEndDate
          coverageType
          assignedPcpFirstName
          assignedPcpMiddleName
          assignedPcpLastName
          assignedPcpNpi
          lineOfBusiness
          referral
        }
        inNetwork {
          individualNetworkDeductibleDetails {
            deductible {
              currentSpent
              maximumAllowedSpent
            }
            outOfPocket {
              currentSpent
              maximumAllowedSpent
            }
          }
          familyNetworkDeductibleDetails {
            deductible {
              currentSpent
              maximumAllowedSpent
            }
            outOfPocket {
              currentSpent
              maximumAllowedSpent
            }
          }
        }
        outOfNetwork {
          individualNetworkDeductibleDetails {
            deductible {
              currentSpent
              maximumAllowedSpent
            }
            outOfPocket {
              currentSpent
              maximumAllowedSpent
            }
          }
          familyNetworkDeductibleDetails {
            deductible {
              currentSpent
              maximumAllowedSpent
            }
            outOfPocket {
              currentSpent
              maximumAllowedSpent
            }
          }
        }
        coPay {
          pcp {
            inNetwork
            outOfNetwork
            frequency
          }
          specialist {
            inNetwork
            outOfNetwork
            frequency
          }
        }
        dataSource
      }
    }
  }
`;
